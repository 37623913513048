.redemption-instructions-prompt {
	display:	none;

	@media @split-screen-down {
		display:	block;
	}
}

.redemption-instructions {
	display:		block;
	position: 		sticky;
	top: 			0;
	border-radius:	10px;

	@media @split-screen-down {
		display:	none;
	}

	.row {
		width:			100%;
		margin-bottom:	2rem;
		color:			@light-grey-text-color;

		&__icon {
			margin-right:	1rem;
			font-size:		2rem;
			color:			@blue-highlight;
		}

		&__step {
			text-align:		left;
		}

		small {
			color:			@light-grey-text-color;
		}

		a {
			text-decoration:	none;
		}
	}

	.text-left {
		p {
			font-size: 12px;
			font-family: Karla, Arial, sans-serif;
		}
	}
}

// modal
.app-dialog-box {
	position:			relative;
	background-color:	#ffffff;
	border-radius:		10px;
	box-sizing: 		border-box;

	.close-button {
		position: 		absolute;
		right: 			2px;
		top: 			2px;
		width: 			24px;
		height: 		24px;
		padding:		8px;
		border-radius:	50%;
		transition:		0.25s ease-in;

		@media @mobile-portrait-down {
			display:	none;
		}

		&:hover {
			cursor:		pointer;
			opacity:	1;
			background:	rgba(22, 24, 35, 0.08);
		}

		&:before, &:after {
			position: 			absolute;
			left: 				18px;
			content: 			' ';
			height: 			25px;
			width: 				3px;
			background-color:	@default-text-color;

			@media @mobile-portrait-down {
				height:		20px;
			}
		}

		&:before {
			transform: rotate(45deg);
		}

		&:after {
			transform: rotate(-45deg);
		}
	}

	.content-wrapper {
		display:	flex;

		@media only screen and (max-width: 610px) {
			flex-direction:	column;
		}

		.art-container {
			width: 			45%;
			padding: 		60px 36px;
			background: 	#f7f7fa;
			border-radius: 	8px;
			@media (max-width: 610px) {
				width:				unset;
				display: 			flex;
				flex-direction: 	row-reverse;
				justify-content: 	flex-start;
				align-items: 		center;
				padding:			24px 36px;
			}

			@media @mobile-portrait-down {
				display:	none;
			}

			.illustration__non-mobile {
				display:		block;
				width:			100%;
				border-radius:	10px;

				@media @mobile-portrait-down {
					display:	none;
				}
			}
			.illustration__mobile {
				display:		none;
				border-radius:	10px;

				@media @mobile-portrait-down {
					display:	block;
				}
			}
			h3 {
				margin:		16px 0;
				font-size:	40px;
				color:		@default-text-color;

				@media (max-width: 610px) {
					font-size:		22px;
					margin-bottom:	8px;
				}
			}
		}

		.text-container {
			width:				55%;
			position:			relative;
			display:			flex;
			flex-direction:		column;
			text-align:			left;
			@media (max-width: 610px) {
				width:	unset;
			}

			.text {
				padding:	36px;
				@media (max-width: 610px) {
					padding:	18px 36px;
				}

				.instructions-wrapper {

					@media @mobile-portrait-down {
						margin-bottom:	0;
					}
				}
			}

			h3 {
				margin:			16px 0 24px 0;
				font-size:		22px;
				color:			@default-text-color;

				@media @mobile-portrait-down {
					font-size:		18px;
				}
			}

			p {
				font-weight:	400;
			}

			.button-container {
				width:		100%;

				@media @mobile-portrait-down {
					position:	relative;
				}

				.ui-nav {
					&.ctas {
						padding:		20px 32px;
						border-top:		1px solid #a2a3a7;
						div,a {
							margin:				2px 0;
							position: 			relative;
							display: 			inline-block;
							padding:			0 25px;
							background-color: 	@prizeout-blue;
							color: 				#ffffff;
							font-size: 			14px;
							line-height: 		36px;
							font-weight: 		600;
							vertical-align: 	middle;
							text-decoration: 	none;
							text-align:			center;
							border-radius: 		5px;
							transition: 		transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
							user-select:		none;
						}

						@media only screen and (max-width: 611px) {
							display:		flex;
							flex-direction:	column-reverse;
							border-top:		none;
						}
					}
				}
			}
		}
	}
}