.inline-button {
	width:				100%;
	height:				100%;
    position: 			relative;
    padding: 			0 25px;
    vertical-align: 	middle;
    background: 		@prizeout-blue;
    color: 				#ffffff;
    font-size: 			14px;
	font-weight: 		600;
	line-height:		normal;
    text-decoration: 	none;
	outline:			0;
	border:				0;
    border-radius: 		10px 8px 8px 10px;
	cursor: 			pointer;
	transition: 		0.2s ease-out;
}

.c-button {
	position: 			relative;
    display: 			inline-flex;
    justify-content: 	center;
    align-items: 		center;
    text-align: 		center;
	white-space: 		nowrap;
    border: 			0;
    border-radius: 		10px;
    outline: 			0;
    user-select: 		none;
    cursor:				pointer;
	
	&--primary {
		padding:		2px 24px;
		color: 			#ffffff;
		background: 	@blue-highlight;
		box-shadow: 	none;
		transition: 	all .25s ease-in-out;
	}

	&--medium {
		min-width: 		80px;
		padding: 		8px 24px;
		font-size: 		16px;
	}
}