.spinner {
	margin:		0 auto;
	color:		@dark-grey-text-color;
	font-size:	3rem;
}

.loading {
	.overlay {
		& > div {
			text-align:	center;
		}
	}
	.loading-wrapper {
		display:				flex;
		flex-direction:			column;
		align-items:			center;
		justify-content:		center;
		border-radius:			10px;

		.logo {
			width: 				100%;
			max-width: 			135px;
			height:				auto;
			position: 			relative;
			z-index: 			2;
		}

		.confetti {
			width:				auto;
			height:				auto;
			position:			absolute;
			top:				-52%;
			top:				0;
			left:				0;
			z-index:			1;
			transform:			scale(1.6);
			transition:			0.15s ease-in-out;
			animation: 			propagateOut
								.8s
								ease-in-out
								0s
								alternate
								infinite
								none
								running;
		}
		
		&:hover {
			.confetti {
				transform:		scale(1.7);
			}
		}
	}
}

@keyframes propagateOut {
	0% {
		opacity:	0;
		transform:	scale(0);
	}
	100% {
		opacity:	1;
		top:		-55%;
		transform:	scale(1.8);
	}
}