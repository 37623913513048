h1,
h2,
h3,
p,
small {
	display:		block;
	margin:			0;
	font-family:	@default-font-family;
	color:			@default-text-color;
}

h1,
h2,
h3 {
	font-weight: 600;
	line-height: 1.2;
}

p,
small,
span {
	font-weight: 400;
	line-height: 1.4;
}

strong {
	color:			inherit;
	font-size:		inherit;
	font-weight:	600;
}

h1[class*="__title"] {
	font-size:		2.5rem;
	text-overflow:	ellipsis;
	overflow:		hidden;

	@media @tablet-portrait-down {
		font-size: 1.5rem;
	}
}

h2[class*="__heading"] {
	font-size: 1.875rem;

	@media @tablet-portrait-down {
		font-size: 1.25rem;
	}
}

h3[class*="__subheading"],
p {
	font-size: 1.25rem;

	@media @tablet-portrait-down {
		font-size: .875rem;
	}
}

small {
	font-size: .7rem;

	&[class*="__meta"] {
		font-size: .625rem;
	}
}

span {
	&[class*="__callout"] {
		font-size:		.9rem;
		font-weight:	400;

		@media @tablet-portrait-down {
			font-size: .875rem;
		}
	}

	&[class*="__option"] {
		color:		@dark-grey-text-color;
		font-size:	.75rem;
	}
}

* {
	.highlight {
		color: @blue-highlight;
	}

	.description {
		font-family:	@alternate-font-family;
		color:			@light-grey-text-color;
	}

	.border-red {
		border:	2px solid red;
	}
}