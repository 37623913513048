// directive-specific
[ng-click] {
	cursor:	pointer;
}
// width
.min-width {
	&--200 {
		min-width:	200px;
	}
}

.max-width {
	&--200 {
		max-width:	200px;
	}
}
// height
.max-height {
	&--300 {
		max-height:	300px;
	}

	&--350 {
		max-height:	350px;
	}

	&--400 {
		max-height:	400px;
	}
}

.flex-container {
	display:		flex;
	align-items: 	center;
}

.align-start {
	align-items:	start;
}

.align-stretch {
	align-items:	stretch;
}

.justify-between {
	justify-content: space-between;
}

.justify-around {
	justify-content: space-around;
}

.justify-evenly {
	justify-content: space-evenly;
}

.justify-center {
	justify-content: center;
}

.flex-column {
	flex-direction:	column;
}

.overflow-x {
	overflow-x:		auto;
}

.overflow-y {
	overflow-y:		auto;
}

.text-center {
	text-align:		center;
}

.text-left {
	text-align:		left;
}

.text-right {
	text-align:		right;
}

.text-italic {
	font-style:		italic;
}

.text-uppercase {
	text-transform: uppercase;
}

.text-capitalize {
	text-transform:	capitalize;
}

.relative-block {
	display:	block;
	position:	relative;
}

// margins
.mt-xs {
    margin-top:     .5rem;
}

.mb-xs {
    margin-bottom:  .5rem;
}

.mr-xs {
	margin-right:	.5rem;
}

.ml-xs {
	margin-left:	.5rem;
}

.mt-small {
    margin-top:     1rem;
}

.mb-small {
    margin-bottom:  1rem;
}

.ml-small {
	margin-left:	1rem;
}

.mr-small {
	margin-right:	1rem;
}

.mr-medium {
    margin-right:   2rem;
}

.ml-medium {
    margin-left:    2rem;
}

.mt-medium {
    margin-top:     2rem;
}

.mb-medium {
    margin-bottom:  2rem;
}

.mt-large {
    margin-top:     4rem;
}

.mb-large {
    margin-bottom:  4rem;
}

// padding
.p-0 {
	padding:	0 !important;
}

.pl-xs {
	padding-left:	.5rem;
}

.pr-xs {
	padding-right:	.5rem;
}

.pt-small {
    padding-top:     1rem;
}

.pb-small {
    padding-bottom:  1rem;
}

.pl-small {
	padding-left:	1rem;
}

.pr-small {
	padding-right:	1rem;
}

.pr-medium {
    padding-right:   2rem;
}

.pl-medium {
    padding-left:    2rem;
}

.pt-medium {
    padding-top:     2rem;
}

.pb-medium {
    padding-bottom:  2rem;
}

.pt-large {
    padding-top:     4rem;
}

.pb-large {
    padding-bottom:  4rem;
}

// fonts
.normal {
	font-weight:	400;
}

.bold {
	font-weight:	600;
}

.lh-0 {
	line-height:	0;
}

.desktop-show {
	display:	none;
	@media @mobile-portrait-down {
		display:	block;
	}
}