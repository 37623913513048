.ui-tools {
	position:				absolute;
	top:					0;
	left:					0;
	width:					100%;
	height:					100%;
	font-family: 			@default-font-family;
	background: 			#f7f7fa;
	overflow-y:				scroll;

	& > .ui-header {
		position:			relative;
		background-color:	@prizeout-blue;
		font-weight:		700;
		font-size:			15px;
		text-align:			center;
		line-height:		45px;
		z-index:			9999;

		& > div.navbar-container {
			box-sizing:			border-box;
			width:				100%;
			display:			flex;
			justify-content:	center;
			padding:			0 1rem;
			height:				60px;
			align-items:		center;

			& > div.logo {
				display:		flex;
				height:			100%;
				padding:		0 16px;
				line-height:	unset;
				float:			left;
				text-align:		left;

				& > div {
					display:			flex;
					align-items:		center;
					background-color:	transparent;
					img {
						height:			36px;
						width:			auto;
						max-width:		100%;
					    margin: 		0;
					    padding: 		0;
					    border-style: 	none;
					}
				}
			}
		}
	}
					
					
	& > .ui-content {
		position:	relative;
		height:		100%;
		width: 		100%;
		max-width: 	1200px;
		margin: 	0 auto;
		color:		@default-text-color;
		text-align:	center;
		margin:		0 auto;

		@media screen and (max-width: 720px) {
			width:		100%;
		}

		.content-container {
			position: 		relative;
			max-width:		800px;
			flex-flow: 		row nowrap;
			margin:			0 auto;
			text-align: 	center;
			color:			@default-text-color;
			border-radius:	10px;
			box-sizing:		border-box;

			@media @tablet-portrait-down {
				padding-top:	1.5rem;
			}

			@media @mobile-portrait-down {
				padding-top:	0;
			}
		}

		.pin-prompt {
			font-family:	@alternate-font-family;

			&--mobile {
				display:		none;
				
				@media @mobile-portrait-down {
					display:	block;
				}
			}

			&--non-mobile {
				display:	none;
				font-size:	.75rem;

				@media @mobile-portrait-up {
					display:	block;
				}
			}
		}

		.redemption-terms {
			padding: 			.5rem 3rem;
			text-align:			left;
			color:				@light-grey-text-color !important;
			border-radius:		4px;

			.text-left {
				p {
					font-size: 12px;
					font-family: Karla, Arial, sans-serif;
				}
			}

			&--mobile {
				display:		none;
				
				@media @mobile-portrait-down {
					display:	block;
				}
			}

			&--non-mobile {
				display:	none;

				@media @mobile-portrait-up {
					display:	block;
				}
			}

			& > small:first-child {
				font-size:		12px;
				color:			@light-grey-text-color !important;
			}

			& > small:last-child {
				font-size:		12px;
				font-family:	@alternate-font-family;
				color:			@light-grey-text-color !important;
			}
			
			a {
				color:				@blue-highlight;
				text-decoration: 	none;
				font-weight:		600;
			}
		}
	}

	/*
		Copy-pasted from Quant-Studio.com with authorization
	*/
	.app-dialog {
		position:	fixed;
		top:		0;
		left:		0;
		width:		100%;
		height:		100%;
		display:	table /*!important*/;
		transition:	opacity ease-out 0.3s, transform ease-out 0.3s;
		z-index:	51000;	/* navbar is 50000 */

		&.front {
			z-index:	52000;
		}

		/* Dialog bg */
		& > .bg {
			position:			absolute;
			top:				0;
			left:				0;
			width:				100%;
			height:				100%;
			background-color:	rgba(0,0,0,0.7);
		}

		.overlay {
			position:	absolute;
			bottom:		0;
			left:		0;
			width:		100%;
			height: 	auto;
			max-height:	100%;
			box-sizing:	border-box;

			@media only screen and (min-width: 481px) {
				height: ~'min(540px, 75%)';
			}

			@media @split-screen-down {
				padding:	0 3rem;
			}

			@media @mobile-portrait-up {
				top: 				50%;
				-webkit-transform: 	translateY(-50%);
  				-ms-transform: 		translateY(-50%);
  				transform: 			translateY(-50%);
			}

			@media @mobile-portrait-down {
				padding:		0;
				vertical-align: bottom;
			}

			.panel {
				height: 			100%;
				background:			#ffffff;
				border-radius:		10px;

				// Allow bottom panel to scroll and swipe
				touch-action: 				pan-y !important;
				-webkit-overflow-scrolling: touch;
				overflow-y: 				auto;

				@media @mobile-portrait-down {
					border-radius:	10px 10px 0 0;
				}
			}

			.h-bar {
				display:			none;
				width: 30%;
				height: 3px;
				margin: 1rem auto;
				border-radius: 10px;
				background-color: rgba(2, 20, 49, 0.3);
				box-shadow: 1px 1px 1px rgba(2, 20, 49, 0.125);

				@media @mobile-portrait-down {
					display:	block;
				}
			}

			& > div {
				max-width:		500px;
				margin:			0 auto;
				text-align:		left;
				border-radius:	5px;

				@media @mobile-portrait-down {
					padding:	0;
				}
			}

			&.form {
				& > div {
					max-width:		500px;
				}
			}
			&.medium {
				& > div {
					max-width:		500px;
				}
			}
			&.large {
				& > div {
					max-width:		800px;
				}
			}

		}
		&.xs {
			& > .overlay {
				& > div {
					max-width:		260px;
				}
			}
		}
		&.small {
			& > .overlay {
				& > div {
					max-width:		400px;
				}
			}
		}
		&.medium {
			& > .overlay {
				& > div {
					max-width:		600px;
				}
			}
		}
		&.large {
			.overlay {
				& > div {
					max-width:		800px;
				}
			}
		}
		&.x-large {
			& > .overlay {
				& > div {
					max-width:		90%;
				}
			}
		}
		
		@transitionSpeed:	0.7s;
		@transitionEasing:	ease-out;


		/* ng-if show animation */
		&.ng-enter {
			/* The main overlay's animation: fade */
			& > .bg {
				transition:	@transitionSpeed @transitionEasing all;
				opacity:	0;
			}

			/* The box's animation: translateY */
			& > .overlay {
				transition:	@transitionSpeed @transitionEasing all;
				transform:	translateY(500px);
			}


			&.ng-enter-active {
				/* The main overlay's animation: fade */
				& > .bg {
					opacity:	1;
				}

				/* The box's animation: translateY */
				& > .overlay {
					transform:	translateY(0);
				}
			}
		}
		/* ng-if hide animation */
		&.ng-leave {
			/* The main overlay's animation: fade */
			& > .bg {
				transition:	@transitionSpeed @transitionEasing all;
				opacity:	1;
			}

			/* The box's animation: translateY */
			& > .overlay {
				transition:	@transitionSpeed @transitionEasing all;
				transform:	translateY(0);
			}

			&.ng-leave-active {
				/* The main overlay's animation: fade */
				& > .bg {
					opacity:	0;
				}

				/* The box's animation: translateY */
				& > .overlay {
					transform:	translateY(500px);
				}
			}
		}

	}
}

