input {
	height:			100%;
	padding:		.5rem;
	font-size:		14px;
	border-radius:	10px;
	border:			2px solid #0214310D;
	outline:		none;
	box-sizing: 	border-box;
}
 
.input-wrapper {
	max-width: 		275px;
	height: 		30px;
	display: 		flex;
	margin-left:	auto;
	margin-right:	auto;
	border-radius: 	10px;
	border: 		1px solid #02143180;

	&--medium {
		max-width:	400px;

		@media @mobile-portrait-down {
			max-width: 		100%;
			margin-left: 	1rem;
			margin-right: 	1rem;
		}
	}

	input {
		flex: 				2;
		border: 			none;
		text-align: 		center;
		border-radius: 		10px 0 0 10px;
	}
	
	button {
		flex:				1;
		padding:			0 8px;
		background-color: 	@blue-highlight;
		color: 				#ffffff;
		border: 			none;
		box-shadow: 		none;
		outline: 			none;
	}

	.label {
		height:			100%;
		flex:			1;
		font-size:		1rem;
		font-family:	@default-font-family;
		border-right:	1px solid @light-grey-text-color;
	}

	.value {
		flex:			2;
		width:			100px;
		min-width:		100px;
	}

	.copy-action {
		padding:		.5rem;
		background:		@blue-highlight;
		color:			#ffffff;
		border-radius:	10px;
		&.copied {
			background:	@prizeout-green;
		}
	}
}
