/* Globals */
/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  height: 100%;
  line-height: 1;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
a {
  text-decoration: none;
}
a.white {
  color: #ffffff;
}
h1,
h2,
h3,
p,
small {
  display: block;
  margin: 0;
  font-family: Lato, Arial, sans-serif;
  color: #021431;
}
h1,
h2,
h3 {
  font-weight: 600;
  line-height: 1.2;
}
p,
small,
span {
  font-weight: 400;
  line-height: 1.4;
}
strong {
  color: inherit;
  font-size: inherit;
  font-weight: 600;
}
h1[class*="__title"] {
  font-size: 2.5rem;
  text-overflow: ellipsis;
  overflow: hidden;
}
@media only screen and (max-width: 768px) {
  h1[class*="__title"] {
    font-size: 1.5rem;
  }
}
h2[class*="__heading"] {
  font-size: 1.875rem;
}
@media only screen and (max-width: 768px) {
  h2[class*="__heading"] {
    font-size: 1.25rem;
  }
}
h3[class*="__subheading"],
p {
  font-size: 1.25rem;
}
@media only screen and (max-width: 768px) {
  h3[class*="__subheading"],
  p {
    font-size: .875rem;
  }
}
small {
  font-size: .7rem;
}
small[class*="__meta"] {
  font-size: .625rem;
}
span[class*="__callout"] {
  font-size: .9rem;
  font-weight: 400;
}
@media only screen and (max-width: 768px) {
  span[class*="__callout"] {
    font-size: .875rem;
  }
}
span[class*="__option"] {
  color: #808998;
  font-size: .75rem;
}
* .highlight {
  color: #00A7FF;
}
* .description {
  font-family: Karla, Arial, sans-serif;
  color: #9098A5;
}
* .border-red {
  border: 2px solid red;
}
/* Fonts */
/* Colors */
/* Border Radius */
/* Box Shadow */
/* Media Queries
	usage: @media @tablet-landscape-down { ... };
*/
[ng-click] {
  cursor: pointer;
}
.min-width--200 {
  min-width: 200px;
}
.max-width--200 {
  max-width: 200px;
}
.max-height--300 {
  max-height: 300px;
}
.max-height--350 {
  max-height: 350px;
}
.max-height--400 {
  max-height: 400px;
}
.flex-container {
  display: flex;
  align-items: center;
}
.align-start {
  align-items: start;
}
.align-stretch {
  align-items: stretch;
}
.justify-between {
  justify-content: space-between;
}
.justify-around {
  justify-content: space-around;
}
.justify-evenly {
  justify-content: space-evenly;
}
.justify-center {
  justify-content: center;
}
.flex-column {
  flex-direction: column;
}
.overflow-x {
  overflow-x: auto;
}
.overflow-y {
  overflow-y: auto;
}
.text-center {
  text-align: center;
}
.text-left {
  text-align: left;
}
.text-right {
  text-align: right;
}
.text-italic {
  font-style: italic;
}
.text-uppercase {
  text-transform: uppercase;
}
.text-capitalize {
  text-transform: capitalize;
}
.relative-block {
  display: block;
  position: relative;
}
.mt-xs {
  margin-top: .5rem;
}
.mb-xs {
  margin-bottom: .5rem;
}
.mr-xs {
  margin-right: .5rem;
}
.ml-xs {
  margin-left: .5rem;
}
.mt-small {
  margin-top: 1rem;
}
.mb-small {
  margin-bottom: 1rem;
}
.ml-small {
  margin-left: 1rem;
}
.mr-small {
  margin-right: 1rem;
}
.mr-medium {
  margin-right: 2rem;
}
.ml-medium {
  margin-left: 2rem;
}
.mt-medium {
  margin-top: 2rem;
}
.mb-medium {
  margin-bottom: 2rem;
}
.mt-large {
  margin-top: 4rem;
}
.mb-large {
  margin-bottom: 4rem;
}
.p-0 {
  padding: 0 !important;
}
.pl-xs {
  padding-left: .5rem;
}
.pr-xs {
  padding-right: .5rem;
}
.pt-small {
  padding-top: 1rem;
}
.pb-small {
  padding-bottom: 1rem;
}
.pl-small {
  padding-left: 1rem;
}
.pr-small {
  padding-right: 1rem;
}
.pr-medium {
  padding-right: 2rem;
}
.pl-medium {
  padding-left: 2rem;
}
.pt-medium {
  padding-top: 2rem;
}
.pb-medium {
  padding-bottom: 2rem;
}
.pt-large {
  padding-top: 4rem;
}
.pb-large {
  padding-bottom: 4rem;
}
.normal {
  font-weight: 400;
}
.bold {
  font-weight: 600;
}
.lh-0 {
  line-height: 0;
}
.desktop-show {
  display: none;
}
@media only screen and (max-width: 480px) {
  .desktop-show {
    display: block;
  }
}
.ui-tools {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font-family: Lato, Arial, sans-serif;
  background: #f7f7fa;
  overflow-y: scroll;
  /*
		Copy-pasted from Quant-Studio.com with authorization
	*/
}
.ui-tools > .ui-header {
  position: relative;
  background-color: #1fb0fa;
  font-weight: 700;
  font-size: 15px;
  text-align: center;
  line-height: 45px;
  z-index: 9999;
}
.ui-tools > .ui-header > div.navbar-container {
  box-sizing: border-box;
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 0 1rem;
  height: 60px;
  align-items: center;
}
.ui-tools > .ui-header > div.navbar-container > div.logo {
  display: flex;
  height: 100%;
  padding: 0 16px;
  line-height: unset;
  float: left;
  text-align: left;
}
.ui-tools > .ui-header > div.navbar-container > div.logo > div {
  display: flex;
  align-items: center;
  background-color: transparent;
}
.ui-tools > .ui-header > div.navbar-container > div.logo > div img {
  height: 36px;
  width: auto;
  max-width: 100%;
  margin: 0;
  padding: 0;
  border-style: none;
}
.ui-tools > .ui-content {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1200px;
  color: #021431;
  text-align: center;
  margin: 0 auto;
}
@media screen and (max-width: 720px) {
  .ui-tools > .ui-content {
    width: 100%;
  }
}
.ui-tools > .ui-content .content-container {
  position: relative;
  max-width: 800px;
  flex-flow: row nowrap;
  margin: 0 auto;
  text-align: center;
  color: #021431;
  border-radius: 10px;
  box-sizing: border-box;
}
@media only screen and (max-width: 768px) {
  .ui-tools > .ui-content .content-container {
    padding-top: 1.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .ui-tools > .ui-content .content-container {
    padding-top: 0;
  }
}
.ui-tools > .ui-content .pin-prompt {
  font-family: Karla, Arial, sans-serif;
}
.ui-tools > .ui-content .pin-prompt--mobile {
  display: none;
}
@media only screen and (max-width: 480px) {
  .ui-tools > .ui-content .pin-prompt--mobile {
    display: block;
  }
}
.ui-tools > .ui-content .pin-prompt--non-mobile {
  display: none;
  font-size: .75rem;
}
@media only screen and (min-width: 481px) {
  .ui-tools > .ui-content .pin-prompt--non-mobile {
    display: block;
  }
}
.ui-tools > .ui-content .redemption-terms {
  padding: .5rem 3rem;
  text-align: left;
  color: #9098A5 !important;
  border-radius: 4px;
}
.ui-tools > .ui-content .redemption-terms .text-left p {
  font-size: 12px;
  font-family: Karla, Arial, sans-serif;
}
.ui-tools > .ui-content .redemption-terms--mobile {
  display: none;
}
@media only screen and (max-width: 480px) {
  .ui-tools > .ui-content .redemption-terms--mobile {
    display: block;
  }
}
.ui-tools > .ui-content .redemption-terms--non-mobile {
  display: none;
}
@media only screen and (min-width: 481px) {
  .ui-tools > .ui-content .redemption-terms--non-mobile {
    display: block;
  }
}
.ui-tools > .ui-content .redemption-terms > small:first-child {
  font-size: 12px;
  color: #9098A5 !important;
}
.ui-tools > .ui-content .redemption-terms > small:last-child {
  font-size: 12px;
  font-family: Karla, Arial, sans-serif;
  color: #9098A5 !important;
}
.ui-tools > .ui-content .redemption-terms a {
  color: #00A7FF;
  text-decoration: none;
  font-weight: 600;
}
.ui-tools .app-dialog {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: table /*!important*/;
  transition: opacity ease-out 0.3s, transform ease-out 0.3s;
  z-index: 51000;
  /* navbar is 50000 */
  /* Dialog bg */
  /* ng-if show animation */
  /* ng-if hide animation */
}
.ui-tools .app-dialog.front {
  z-index: 52000;
}
.ui-tools .app-dialog > .bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
}
.ui-tools .app-dialog .overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: auto;
  max-height: 100%;
  box-sizing: border-box;
}
@media only screen and (min-width: 481px) {
  .ui-tools .app-dialog .overlay {
    height: min(540px, 75%);
  }
}
@media only screen and (max-width: 960px) {
  .ui-tools .app-dialog .overlay {
    padding: 0 3rem;
  }
}
@media only screen and (min-width: 481px) {
  .ui-tools .app-dialog .overlay {
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
  }
}
@media only screen and (max-width: 480px) {
  .ui-tools .app-dialog .overlay {
    padding: 0;
    vertical-align: bottom;
  }
}
.ui-tools .app-dialog .overlay .panel {
  height: 100%;
  background: #ffffff;
  border-radius: 10px;
  touch-action: pan-y !important;
  -webkit-overflow-scrolling: touch;
  overflow-y: auto;
}
@media only screen and (max-width: 480px) {
  .ui-tools .app-dialog .overlay .panel {
    border-radius: 10px 10px 0 0;
  }
}
.ui-tools .app-dialog .overlay .h-bar {
  display: none;
  width: 30%;
  height: 3px;
  margin: 1rem auto;
  border-radius: 10px;
  background-color: rgba(2, 20, 49, 0.3);
  box-shadow: 1px 1px 1px rgba(2, 20, 49, 0.125);
}
@media only screen and (max-width: 480px) {
  .ui-tools .app-dialog .overlay .h-bar {
    display: block;
  }
}
.ui-tools .app-dialog .overlay > div {
  max-width: 500px;
  margin: 0 auto;
  text-align: left;
  border-radius: 5px;
}
@media only screen and (max-width: 480px) {
  .ui-tools .app-dialog .overlay > div {
    padding: 0;
  }
}
.ui-tools .app-dialog .overlay.form > div {
  max-width: 500px;
}
.ui-tools .app-dialog .overlay.medium > div {
  max-width: 500px;
}
.ui-tools .app-dialog .overlay.large > div {
  max-width: 800px;
}
.ui-tools .app-dialog.xs > .overlay > div {
  max-width: 260px;
}
.ui-tools .app-dialog.small > .overlay > div {
  max-width: 400px;
}
.ui-tools .app-dialog.medium > .overlay > div {
  max-width: 600px;
}
.ui-tools .app-dialog.large .overlay > div {
  max-width: 800px;
}
.ui-tools .app-dialog.x-large > .overlay > div {
  max-width: 90%;
}
.ui-tools .app-dialog.ng-enter {
  /* The main overlay's animation: fade */
  /* The box's animation: translateY */
}
.ui-tools .app-dialog.ng-enter > .bg {
  transition: 0.7s ease-out all;
  opacity: 0;
}
.ui-tools .app-dialog.ng-enter > .overlay {
  transition: 0.7s ease-out all;
  transform: translateY(500px);
}
.ui-tools .app-dialog.ng-enter.ng-enter-active {
  /* The main overlay's animation: fade */
  /* The box's animation: translateY */
}
.ui-tools .app-dialog.ng-enter.ng-enter-active > .bg {
  opacity: 1;
}
.ui-tools .app-dialog.ng-enter.ng-enter-active > .overlay {
  transform: translateY(0);
}
.ui-tools .app-dialog.ng-leave {
  /* The main overlay's animation: fade */
  /* The box's animation: translateY */
}
.ui-tools .app-dialog.ng-leave > .bg {
  transition: 0.7s ease-out all;
  opacity: 1;
}
.ui-tools .app-dialog.ng-leave > .overlay {
  transition: 0.7s ease-out all;
  transform: translateY(0);
}
.ui-tools .app-dialog.ng-leave.ng-leave-active {
  /* The main overlay's animation: fade */
  /* The box's animation: translateY */
}
.ui-tools .app-dialog.ng-leave.ng-leave-active > .bg {
  opacity: 0;
}
.ui-tools .app-dialog.ng-leave.ng-leave-active > .overlay {
  transform: translateY(500px);
}
/* Components */
.spinner {
  margin: 0 auto;
  color: #808998;
  font-size: 3rem;
}
.loading .overlay > div {
  text-align: center;
}
.loading .loading-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
}
.loading .loading-wrapper .logo {
  width: 100%;
  max-width: 135px;
  height: auto;
  position: relative;
  z-index: 2;
}
.loading .loading-wrapper .confetti {
  width: auto;
  height: auto;
  position: absolute;
  top: -52%;
  top: 0;
  left: 0;
  z-index: 1;
  transform: scale(1.6);
  transition: 0.15s ease-in-out;
  animation: propagateOut 0.8s ease-in-out 0s alternate infinite none running;
}
.loading .loading-wrapper:hover .confetti {
  transform: scale(1.7);
}
@keyframes propagateOut {
  0% {
    opacity: 0;
    transform: scale(0);
  }
  100% {
    opacity: 1;
    top: -55%;
    transform: scale(1.8);
  }
}
.inline-button {
  width: 100%;
  height: 100%;
  position: relative;
  padding: 0 25px;
  vertical-align: middle;
  background: #1fb0fa;
  color: #ffffff;
  font-size: 14px;
  font-weight: 600;
  line-height: normal;
  text-decoration: none;
  outline: 0;
  border: 0;
  border-radius: 10px 8px 8px 10px;
  cursor: pointer;
  transition: 0.2s ease-out;
}
.c-button {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  white-space: nowrap;
  border: 0;
  border-radius: 10px;
  outline: 0;
  user-select: none;
  cursor: pointer;
}
.c-button--primary {
  padding: 2px 24px;
  color: #ffffff;
  background: #00A7FF;
  box-shadow: none;
  transition: all 0.25s ease-in-out;
}
.c-button--medium {
  min-width: 80px;
  padding: 8px 24px;
  font-size: 16px;
}
input {
  height: 100%;
  padding: .5rem;
  font-size: 14px;
  border-radius: 10px;
  border: 2px solid #0214310D;
  outline: none;
  box-sizing: border-box;
}
.input-wrapper {
  max-width: 275px;
  height: 30px;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  border-radius: 10px;
  border: 1px solid #02143180;
}
.input-wrapper--medium {
  max-width: 400px;
}
@media only screen and (max-width: 480px) {
  .input-wrapper--medium {
    max-width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.input-wrapper input {
  flex: 2;
  border: none;
  text-align: center;
  border-radius: 10px 0 0 10px;
}
.input-wrapper button {
  flex: 1;
  padding: 0 8px;
  background-color: #00A7FF;
  color: #ffffff;
  border: none;
  box-shadow: none;
  outline: none;
}
.input-wrapper .label {
  height: 100%;
  flex: 1;
  font-size: 1rem;
  font-family: Lato, Arial, sans-serif;
  border-right: 1px solid #9098A5;
}
.input-wrapper .value {
  flex: 2;
  width: 100px;
  min-width: 100px;
}
.input-wrapper .copy-action {
  padding: .5rem;
  background: #00A7FF;
  color: #ffffff;
  border-radius: 10px;
}
.input-wrapper .copy-action.copied {
  background: #1ffab7;
}
.tpd-tooltip {
  position: absolute;
}
/* Fix for CSS frameworks that don't keep the use of box-sizing: border-box
	 within their own namespace */
.tpd-tooltip {
  box-sizing: content-box;
}
.tpd-tooltip [class^="tpd-"] {
  box-sizing: inherit;
}
/* Content */
.tpd-content-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  float: left;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.tpd-content-spacer,
.tpd-content-relative,
.tpd-content-relative-padder {
  float: left;
  position: relative;
}
.tpd-content-relative {
  width: 100%;
}
.tpd-content {
  float: left;
  clear: both;
  position: relative;
  padding: 10px;
  font-size: 11px;
  line-height: 16px;
  color: #fff;
  box-sizing: border-box !important;
}
.tpd-has-inner-close .tpd-content-relative .tpd-content {
  padding-right: 0 !important;
}
.tpd-tooltip .tpd-content-no-padding {
  padding: 0 !important;
}
.tpd-title-wrapper {
  float: left;
  position: relative;
  overflow: hidden;
}
.tpd-title-spacer {
  float: left;
}
.tpd-title-relative,
.tpd-title-relative-padder {
  float: left;
  position: relative;
}
.tpd-title-relative {
  width: 100%;
}
.tpd-title {
  float: left;
  position: relative;
  font-size: 11px;
  line-height: 16px;
  padding: 10px;
  font-weight: bold;
  text-transform: uppercase;
  color: #fff;
  box-sizing: border-box !important;
}
.tpd-has-title-close .tpd-title {
  padding-right: 0 !important;
}
.tpd-close {
  position: absolute;
  top: 0;
  right: 0;
  width: 28px;
  height: 28px;
  cursor: pointer;
  overflow: hidden;
  color: #fff;
}
.tpd-close-icon {
  float: left;
  font-family: Arial, Baskerville, monospace;
  font-weight: normal;
  font-style: normal;
  text-decoration: none;
  width: 28px;
  height: 28px;
  font-size: 28px;
  line-height: 28px;
  text-align: center;
}
/* Skin */
.tpd-skin {
  position: absolute;
  top: 0;
  left: 0;
}
.tpd-frames {
  position: absolute;
  top: 0;
  left: 0;
}
.tpd-frames .tpd-frame {
  float: left;
  width: 100%;
  height: 100%;
  clear: both;
  display: none;
}
.tpd-visible-frame-top .tpd-frame-top {
  display: block;
}
.tpd-visible-frame-bottom .tpd-frame-bottom {
  display: block;
}
.tpd-visible-frame-left .tpd-frame-left {
  display: block;
}
.tpd-visible-frame-right .tpd-frame-right {
  display: block;
}
.tpd-backgrounds {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}
.tpd-background-shadow {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  pointer-events: none;
}
.tpd-no-shadow .tpd-skin .tpd-background-shadow {
  box-shadow: none !important;
}
.tpd-background-box {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
}
/* only the top background box should be shown when not using a stem */
.tpd-no-stem .tpd-background-box,
.tpd-no-stem .tpd-shift-stem {
  display: none;
}
.tpd-no-stem .tpd-background-box-top {
  display: block;
}
.tpd-background-box-shift,
.tpd-background-box-shift-further {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}
.tpd-background {
  border-radius: 10px;
  float: left;
  clear: both;
  background: none;
  -webkit-background-clip: padding-box;
  /* Safari */
  background-clip: padding-box;
  /* IE9+, Firefox 4+, Opera, Chrome */
  border-style: solid;
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.1);
  /* opacity here bugs out in firefox, .tpd-background-content should have no opacity if this opacity is less than 1 */
}
.tpd-background-loading {
  display: none;
}
/* no radius */
.tpd-no-radius .tpd-skin .tpd-frames .tpd-frame .tpd-backgrounds .tpd-background {
  border-radius: 10px;
}
.tpd-background-title {
  float: left;
  clear: both;
  width: 100%;
  background-color: #282828;
}
.tpd-background-content {
  float: left;
  clear: both;
  width: 100%;
  background-color: #282828;
}
.tpd-background-border-hack {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-style: solid;
}
.tpd-background-box-top {
  top: 0;
}
.tpd-background-box-bottom {
  bottom: 0;
}
.tpd-background-box-left {
  left: 0;
}
.tpd-background-box-right {
  right: 0;
}
/* Skin / Stems */
.tpd-shift-stem {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
}
.tpd-shift-stem-side {
  position: absolute;
}
.tpd-frame-top .tpd-shift-stem-side,
.tpd-frame-bottom .tpd-shift-stem-side {
  width: 100%;
}
.tpd-frame-left .tpd-shift-stem-side,
.tpd-frame-right .tpd-shift-stem-side {
  height: 100%;
}
.tpd-stem {
  position: absolute;
  top: 0;
  left: 0;
  overflow: hidden;
  /* shows possible invalid subpx rendering */
  width: 16px;
  /* best cross browser stem: width = 2 x height (90deg angle) */
  height: 8px;
  margin-left: 3px;
  /* space from the side */
  margin-top: 2px;
  /* space between target and stem */
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
}
/* remove margins once we're done measuring */
.tpd-tooltip .tpd-skin .tpd-frames .tpd-frame .tpd-shift-stem .tpd-stem-reset {
  margin: 0 !important;
}
.tpd-stem-spacer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.tpd-stem-reset .tpd-stem-spacer {
  margin-top: 0;
}
.tpd-stem-point {
  width: 100px;
  position: absolute;
  top: 0;
  left: 50%;
}
.tpd-stem-downscale,
.tpd-stem-transform {
  float: left;
  width: 100%;
  height: 100%;
  -webkit-transform-origin: 0% 0%;
  transform-origin: 0% 0%;
  position: relative;
}
.tpd-stem-side {
  width: 50%;
  height: 100%;
  float: left;
  position: relative;
  overflow: hidden;
}
.tpd-stem-side-inversed {
  -webkit-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.tpd-stem-triangle {
  width: 0;
  height: 0;
  border-bottom-style: solid;
  border-left-color: transparent;
  border-left-style: solid;
  position: absolute;
  top: 0;
  left: 0;
}
.tpd-stem-border {
  width: 20px;
  height: 100%;
  position: absolute;
  top: 0;
  left: 50%;
  background-color: #fff;
  /* will become transparent */
  border-right-color: #fff;
  border-right-style: solid;
  border-right-width: 0;
}
.tpd-stem-border-corner {
  position: absolute;
  top: 0;
  left: 50%;
  height: 100%;
  border-right-style: solid;
  border-right-width: 0;
}
/* fixes rendering issue in IE */
.tpd-stem * {
  z-index: 0;
  zoom: 1;
}
/* used by IE < 9 */
.tpd-stem-border-center-offset,
.tpd-stem-border-center-offset-inverse {
  float: left;
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
}
.tpd-stem-notransform {
  float: left;
  width: 100%;
  height: 100%;
  position: relative;
}
.tpd-stem-notransform .tpd-stem-border {
  height: 100%;
  position: relative;
  float: left;
  top: 0;
  left: 0;
  margin: 0;
}
.tpd-stem-notransform .tpd-stem-border-center {
  position: absolute;
}
.tpd-stem-notransform .tpd-stem-border-corner {
  background: #fff;
  border: 0;
  top: auto;
  left: auto;
}
.tpd-stem-notransform .tpd-stem-border-center,
.tpd-stem-notransform .tpd-stem-triangle {
  height: 0;
  border: 0;
  left: 50%;
}
/* transformations for left/right/bottom */
.tpd-stem-transform-left {
  -webkit-transform: rotate(-90deg) scale(-1, 1);
  transform: rotate(-90deg) scale(-1, 1);
}
.tpd-stem-transform-right {
  -webkit-transform: rotate(90deg) translate(0, -100%);
  transform: rotate(90deg) translate(0, -100%);
}
.tpd-stem-transform-bottom {
  -webkit-transform: scale(1, -1) translate(0, -100%);
  transform: scale(1, -1) translate(0, -100%);
}
/* Spinner */
.tpd-spinner {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 46px;
  height: 36px;
}
.tpd-spinner-spin {
  position: relative;
  float: left;
  margin: 8px 0 0 13px;
  text-indent: -9999em;
  border-top: 2px solid rgba(255, 255, 255, 0.2);
  border-right: 2px solid rgba(255, 255, 255, 0.2);
  border-bottom: 2px solid rgba(255, 255, 255, 0.2);
  border-left: 2px solid #fff;
  -webkit-animation: tpd-spinner-animation 1.1s infinite linear;
  animation: tpd-spinner-animation 1.1s infinite linear;
  box-sizing: border-box !important;
}
.tpd-spinner-spin,
.tpd-spinner-spin:after {
  border-radius: 50%;
  width: 20px;
  height: 20px;
}
@-webkit-keyframes tpd-spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes tpd-spinner-animation {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
/* show the loader while loading and hide all the content */
.tpd-is-loading .tpd-content-wrapper,
.tpd-is-loading .tpd-title-wrapper {
  display: none;
}
.tpd-is-loading .tpd-background {
  display: none;
}
.tpd-is-loading .tpd-background-loading {
  display: block;
}
/* Resets while measuring content */
.tpd-tooltip-measuring {
  top: 0;
  left: 0;
  position: absolute;
  max-width: 100%;
  width: 100%;
}
.tpd-tooltip-measuring .tpd-skin,
.tpd-tooltip-measuring .tpd-spinner {
  display: none;
}
.tpd-tooltip-measuring .tpd-content-wrapper,
.tpd-tooltip-measuring .tpd-title-wrapper {
  display: block;
}
/* Links */
.tpd-tooltip a,
.tpd-tooltip a:hover {
  color: #808080;
  text-decoration: underline;
}
.tpd-tooltip a:hover {
  color: #6c6c6c;
}
/*
   * Sizes
   */
/* x-small */
.tpd-size-x-small .tpd-content,
.tpd-size-x-small .tpd-title {
  padding: 7px 8px;
  font-size: 10px;
  line-height: 15px;
}
.tpd-size-x-small .tpd-background {
  border-radius: 5px;
}
.tpd-size-x-small .tpd-stem {
  width: 12px;
  height: 6px;
  margin-left: 4px;
  margin-top: 2px;
  /* space between target and stem */
}
.tpd-size-x-small.tpd-no-radius .tpd-stem {
  margin-left: 7px;
}
.tpd-size-x-small .tpd-close {
  margin-bottom: 1px;
}
.tpd-size-x-small .tpd-spinner {
  width: 35px;
  height: 29px;
}
.tpd-size-x-small .tpd-spinner-spin {
  margin: 6px 0 0 9px;
}
.tpd-size-x-small .tpd-spinner-spin,
.tpd-size-x-small .tpd-spinner-spin:after {
  width: 17px;
  height: 17px;
}
/* small */
.tpd-size-small .tpd-content,
.tpd-size-small .tpd-title {
  padding: 8px;
  font-size: 10px;
  line-height: 16px;
}
.tpd-size-small .tpd-background {
  border-radius: 6px;
}
.tpd-size-small .tpd-stem {
  width: 14px;
  height: 7px;
  margin-left: 5px;
  margin-top: 2px;
  /* space between target and stem */
}
.tpd-size-small.tpd-no-radius .tpd-stem {
  margin-left: 8px;
}
.tpd-size-small .tpd-close {
  margin: 2px 1px;
}
.tpd-size-small .tpd-spinner {
  width: 42px;
  height: 32px;
}
.tpd-size-small .tpd-spinner-spin {
  margin: 7px 0 0 13px;
}
.tpd-size-small .tpd-spinner-spin,
.tpd-size-small .tpd-spinner-spin:after {
  width: 18px;
  height: 18px;
}
/* medium (default) */
.tpd-size-medium .tpd-content,
.tpd-size-medium .tpd-title {
  padding: 10px;
  font-size: 11px;
  line-height: 16px;
}
.tpd-size-medium .tpd-background {
  border-radius: 8px;
}
.tpd-size-medium .tpd-stem {
  width: 16px;
  /* best cross browser stem width is 2xheight, for a 90deg angle */
  height: 8px;
  margin-left: 6px;
  /* space from the side */
  margin-top: 2px;
  /* space between target and stem */
}
.tpd-size-medium.tpd-no-radius .tpd-stem {
  margin-left: 10px;
}
.tpd-size-medium .tpd-close {
  margin: 4px 2px;
}
/* ideal spinner dimensions don't cause movement op top and
	 on the stem when switching to text using position:'topleft' */
.tpd-size-medium .tpd-spinner {
  width: 50px;
  height: 36px;
}
.tpd-size-medium .tpd-spinner-spin {
  margin: 8px 0 0 15px;
}
.tpd-size-medium .tpd-spinner-spin,
.tpd-size-medium .tpd-spinner-spin:after {
  width: 20px;
  height: 20px;
}
/* large */
.tpd-size-large .tpd-content,
.tpd-size-large .tpd-title {
  padding: 10px;
  font-size: 13px;
  line-height: 18px;
}
.tpd-size-large .tpd-background {
  border-radius: 8px;
}
.tpd-size-large .tpd-stem {
  width: 18px;
  height: 9px;
  margin-left: 7px;
  margin-top: 2px;
  /* space between target and stem */
}
.tpd-size-large.tpd-no-radius .tpd-stem {
  margin-left: 10px;
}
.tpd-size-large .tpd-close {
  margin: 5px 2px 5px 2px;
}
.tpd-size-large .tpd-spinner {
  width: 54px;
  height: 38px;
}
.tpd-size-large .tpd-spinner-spin {
  margin: 9px 0 0 17px;
}
.tpd-size-large .tpd-spinner-spin,
.tpd-size-large .tpd-spinner-spin:after {
  width: 20px;
  height: 20px;
}
/* Skins */
/* default (dark) */
.tpd-skin-dark .tpd-content,
.tpd-skin-dark .tpd-title,
.tpd-skin-dark .tpd-close {
  color: #fff;
}
.tpd-skin-dark .tpd-background-content,
.tpd-skin-dark .tpd-background-title {
  background-color: #282828;
}
.tpd-skin-dark .tpd-background {
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.1);
}
/* line below the title */
.tpd-skin-dark .tpd-title-wrapper {
  border-bottom: 1px solid #404040;
}
/* spinner */
.tpd-skin-dark .tpd-spinner-spin {
  border-color: rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
}
/* links */
.tpd-skin-dark a {
  color: #ccc;
}
.tpd-skin-dark a:hover {
  color: #c0c0c0;
}
/* light */
.tpd-skin-light .tpd-content,
.tpd-skin-light .tpd-title,
.tpd-skin-light .tpd-close {
  color: #333;
}
.tpd-skin-light .tpd-background-content {
  background-color: #fff;
}
.tpd-skin-light .tpd-background {
  border-width: 1px;
  border-color: rgba(0, 0, 0, 0.3);
}
.tpd-skin-light .tpd-background-title {
  background-color: #f7f7f7;
}
.tpd-skin-light .tpd-title-wrapper {
  border-bottom: 1px solid #c0c0c0;
}
.tpd-skin-light .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
/* fallback for no/disabled shadow */
.tpd-skin-light.tpd-no-shadow .tpd-background {
  border-color: rgba(100, 100, 100, 0.3);
}
.tpd-skin-light .tpd-spinner-spin {
  border-color: rgba(51, 51, 51, 0.2);
  border-left-color: #333;
}
.tpd-skin-light a {
  color: #808080;
}
.tpd-skin-light a:hover {
  color: #6c6c6c;
}
/* gray */
.tpd-skin-gray .tpd-content,
.tpd-skin-gray .tpd-title,
.tpd-skin-gray .tpd-close {
  color: #fff;
}
.tpd-skin-gray .tpd-background-content,
.tpd-skin-gray .tpd-background-title {
  background-color: #727272;
}
.tpd-skin-gray .tpd-background {
  border-width: 1px;
  border-color: rgba(255, 255, 255, 0.1);
}
.tpd-skin-gray .tpd-title-wrapper {
  border-bottom: 1px solid #505050;
}
.tpd-skin-gray .tpd-spinner-spin {
  border-color: rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
}
.tpd-skin-gray a {
  color: #ccc;
}
.tpd-skin-gray a:hover {
  color: #b6b6b6;
}
/* red */
.tpd-skin-red .tpd-content,
.tpd-skin-red .tpd-title,
.tpd-skin-red .tpd-close {
  color: #fff;
}
.tpd-skin-red .tpd-background-content {
  background-color: #e13c37;
}
.tpd-skin-red .tpd-background {
  border-width: 1px;
  border-color: rgba(12, 0, 0, 0.6);
}
.tpd-skin-red .tpd-background-title {
  background-color: #e13c37;
}
.tpd-skin-red .tpd-title-wrapper {
  border-bottom: 1px solid #a30500;
}
.tpd-skin-red .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
.tpd-skin-red .tpd-spinner-spin {
  border-color: rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
}
.tpd-skin-red a {
  color: #ddd;
}
.tpd-skin-red a:hover {
  color: #c6c6c6;
}
/* green */
.tpd-skin-green .tpd-content,
.tpd-skin-green .tpd-title,
.tpd-skin-green .tpd-close {
  color: #fff;
}
.tpd-skin-green .tpd-background-content {
  background-color: #4aab3a;
}
.tpd-skin-green .tpd-background {
  border-width: 1px;
  border-color: rgba(0, 12, 0, 0.6);
}
.tpd-skin-green .tpd-background-title {
  background-color: #4aab3a;
}
.tpd-skin-green .tpd-title-wrapper {
  border-bottom: 1px solid #127c00;
}
.tpd-skin-green .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
.tpd-skin-green .tpd-spinner-spin {
  border-color: rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
}
.tpd-skin-green a {
  color: #ddd;
}
.tpd-skin-green a:hover {
  color: #c6c6c6;
}
/* blue */
.tpd-skin-blue .tpd-content,
.tpd-skin-blue .tpd-title,
.tpd-skin-blue .tpd-close {
  color: #fff;
}
.tpd-skin-blue .tpd-background-content {
  background-color: #45a3e3;
}
.tpd-skin-blue .tpd-background {
  border-width: 1px;
  border-color: rgba(0, 0, 12, 0.6);
}
.tpd-skin-blue .tpd-background-title {
  background-color: #45a3e3;
}
.tpd-skin-blue .tpd-title-wrapper {
  border-bottom: 1px solid #1674b4;
}
.tpd-skin-blue .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
.tpd-skin-blue .tpd-spinner-spin {
  border-color: rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
}
.tpd-skin-blue a {
  color: #ddd;
}
.tpd-skin-blue a:hover {
  color: #c6c6c6;
}
/* lightyellow */
.tpd-skin-lightyellow .tpd-content,
.tpd-skin-lightyellow .tpd-title,
.tpd-skin-lightyellow .tpd-close {
  color: #333;
}
.tpd-skin-lightyellow .tpd-background-content {
  background-color: #ffffa9;
}
.tpd-skin-lightyellow .tpd-background {
  border-width: 1px;
  border-color: rgba(8, 8, 0, 0.35);
}
.tpd-skin-lightyellow .tpd-background-title {
  background-color: #ffffa9;
}
.tpd-skin-lightyellow .tpd-title-wrapper {
  border-bottom: 1px solid #a7a697;
}
.tpd-skin-lightyellow .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
.tpd-skin-lightyellow .tpd-spinner-spin {
  border-color: rgba(51, 51, 51, 0.2);
  border-left-color: #333;
}
.tpd-skin-lightyellow a {
  color: #777;
}
.tpd-skin-lightyellow a:hover {
  color: #868686;
}
/* lightblue */
.tpd-skin-lightblue .tpd-content,
.tpd-skin-lightblue .tpd-title,
.tpd-skin-lightblue .tpd-close {
  color: #333;
}
.tpd-skin-lightblue .tpd-background-content {
  background-color: #bce5ff;
}
.tpd-skin-lightblue .tpd-background {
  border-width: 1px;
  border-color: rgba(0, 0, 8, 0.35);
}
.tpd-skin-lightblue .tpd-background-title {
  background-color: #bce5ff;
}
.tpd-skin-lightblue .tpd-title-wrapper {
  border-bottom: 1px solid #909b9f;
}
.tpd-skin-lightblue .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
.tpd-skin-lightblue .tpd-spinner-spin {
  border-color: rgba(51, 51, 51, 0.2);
  border-left-color: #333;
}
.tpd-skin-lightblue a {
  color: #777;
}
.tpd-skin-lightblue a:hover {
  color: #868686;
}
/* lightpink */
.tpd-skin-lightpink .tpd-content,
.tpd-skin-lightpink .tpd-title,
.tpd-skin-lightpink .tpd-close {
  color: #333;
}
.tpd-skin-lightpink .tpd-background-content {
  background-color: #ffc4bf;
}
.tpd-skin-lightpink .tpd-background {
  border-width: 1px;
  border-color: rgba(8, 0, 0, 0.35);
}
.tpd-skin-lightpink .tpd-background-title {
  background-color: #ffc4bf;
}
.tpd-skin-lightpink .tpd-title-wrapper {
  border-bottom: 1px solid #a08f8f;
}
.tpd-skin-lightpink .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
.tpd-skin-lightpink .tpd-spinner-spin {
  border-color: rgba(51, 51, 51, 0.2);
  border-left-color: #333;
}
.tpd-skin-lightpink a {
  color: #777;
}
.tpd-skin-lightpink a:hover {
  color: #868686;
}
/* overrides */
.tpd-skin-dark .tpd-content,
.tpd-skin-dark .tpd-title,
.tpd-skin-dark .tpd-close {
  color: #021431;
  font-family: Lato, Arial, sans-serif;
  font-weight: 500;
}
.tpd-skin-dark .tpd-background-content {
  background-color: #e5e7ea;
}
.tpd-skin-dark .tpd-background {
  border-width: 1px;
  border-color: rgba(6, 0, 12, 0.6);
  border: 0;
}
.tpd-skin-dark .tpd-background,
.tpd-skin-dark .tpd-background-hack {
  max-width: 200px;
  border: 0 !important;
  box-shadow: none;
}
.tpd-skin-dark .tpd-background-shadow {
  box-shadow: none;
}
.tpd-skin-dark .tpd-close {
  width: 24px;
  height: 24px;
  border: 1px solid #021431;
  border-radius: 50%;
}
.tpd-skin-dark .tpd-close-icon {
  width: 24px;
  height: 24px;
  font-size: 24px;
  line-height: 24px;
}
.tpd-skin-dark .tpd-background-title {
  background: #e5e7ea;
}
/* line below the title */
.tpd-skin-dark .tpd-title-wrapper {
  border-bottom: 1px solid #2a1fb4;
}
/* shadow */
.tpd-skin-dark .tpd-background-shadow {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);
}
/* spinner */
.tpd-skin-dark .tpd-spinner-spin {
  border-color: rgba(255, 255, 255, 0.2);
  border-left-color: #fff;
}
/* links */
.tpd-skin-dark a {
  color: #ddd;
}
.tpd-skin-dark a:hover {
  color: #c6c6c6;
}
.purple {
  background: #8090fa;
}
.claim-content {
  background: #ffffff;
  border-radius: 10px;
}
@media only screen and (max-width: 960px) {
  .claim-content {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .claim-content {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.gift-card-content {
  background: #ffffff;
  border-radius: 10px;
}
@media only screen and (max-width: 960px) {
  .gift-card-content {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
}
@media only screen and (max-width: 480px) {
  .gift-card-content {
    margin-top: 1rem;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.gift-card-content .image-wrapper {
  max-width: 330px;
  position: relative;
  margin: 0 auto;
  margin-bottom: 2rem;
  border: .625rem solid #ffffff;
  border-bottom: none;
  border-radius: 10px;
}
@media only screen and (max-width: 480px) {
  .gift-card-content .image-wrapper {
    max-width: 200px;
  }
}
.gift-card-content .image-wrapper > img {
  width: 100%;
}
.gift-card-content .image-wrapper > div {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  border-radius: 10px;
}
.gift-card-content .input-wrapper:first-child {
  margin-top: 2rem;
  margin-bottom: 8px;
}
.gift-card-content .copied-text {
  display: none;
  max-width: 400px;
  margin: 12px auto;
  padding: 8px 0;
  color: #ffffff;
  background: #51fbc7;
  border-radius: 0;
  border-color: #1ffab7;
}
@media only screen and (max-width: 480px) {
  .gift-card-content .copied-text {
    max-width: 100%;
    margin-left: 1rem;
    margin-right: 1rem;
  }
}
.gift-card-content .content-break {
  position: relative;
}
.gift-card-content .content-break .break {
  width: 100px;
  height: 1px;
  background: #acb2bc;
}
@media only screen and (max-width: 1024px) {
  .gift-card-content .content-break .break {
    width: 175px;
  }
}
@media only screen and (max-width: 480px) {
  .gift-card-content .content-break .break {
    width: 81px;
  }
}
.gift-card-content .content-break .break__left {
  margin-left: 24px;
}
@media only screen and (max-width: 480px) {
  .gift-card-content .content-break .break__left {
    margin-left: 1rem;
  }
}
.gift-card-content .content-break .break__right {
  margin-right: 24px;
}
@media only screen and (max-width: 480px) {
  .gift-card-content .content-break .break__right {
    margin-right: 1rem;
  }
}
.gift-card-content .content-break:before {
  left: -12px;
}
.gift-card-content .content-break:after {
  right: -12px;
}
.gift-card-content .content-break:before,
.gift-card-content .content-break:after {
  content: " ";
  position: absolute;
  width: 24px;
  height: 24px;
  background: #f7f7fa;
  border-radius: 50%;
}
.gift-card-content table {
  margin: 1rem auto;
}
.redemption-instructions-prompt {
  display: none;
}
@media only screen and (max-width: 960px) {
  .redemption-instructions-prompt {
    display: block;
  }
}
.redemption-instructions {
  display: block;
  position: sticky;
  top: 0;
  border-radius: 10px;
}
@media only screen and (max-width: 960px) {
  .redemption-instructions {
    display: none;
  }
}
.redemption-instructions .row {
  width: 100%;
  margin-bottom: 2rem;
  color: #9098A5;
}
.redemption-instructions .row__icon {
  margin-right: 1rem;
  font-size: 2rem;
  color: #00A7FF;
}
.redemption-instructions .row__step {
  text-align: left;
}
.redemption-instructions .row small {
  color: #9098A5;
}
.redemption-instructions .row a {
  text-decoration: none;
}
.redemption-instructions .text-left p {
  font-size: 12px;
  font-family: Karla, Arial, sans-serif;
}
.app-dialog-box {
  position: relative;
  background-color: #ffffff;
  border-radius: 10px;
  box-sizing: border-box;
}
.app-dialog-box .close-button {
  position: absolute;
  right: 2px;
  top: 2px;
  width: 24px;
  height: 24px;
  padding: 8px;
  border-radius: 50%;
  transition: 0.25s ease-in;
}
@media only screen and (max-width: 480px) {
  .app-dialog-box .close-button {
    display: none;
  }
}
.app-dialog-box .close-button:hover {
  cursor: pointer;
  opacity: 1;
  background: rgba(22, 24, 35, 0.08);
}
.app-dialog-box .close-button:before,
.app-dialog-box .close-button:after {
  position: absolute;
  left: 18px;
  content: ' ';
  height: 25px;
  width: 3px;
  background-color: #021431;
}
@media only screen and (max-width: 480px) {
  .app-dialog-box .close-button:before,
  .app-dialog-box .close-button:after {
    height: 20px;
  }
}
.app-dialog-box .close-button:before {
  transform: rotate(45deg);
}
.app-dialog-box .close-button:after {
  transform: rotate(-45deg);
}
.app-dialog-box .content-wrapper {
  display: flex;
}
@media only screen and (max-width: 610px) {
  .app-dialog-box .content-wrapper {
    flex-direction: column;
  }
}
.app-dialog-box .content-wrapper .art-container {
  width: 45%;
  padding: 60px 36px;
  background: #f7f7fa;
  border-radius: 8px;
}
@media (max-width: 610px) {
  .app-dialog-box .content-wrapper .art-container {
    width: unset;
    display: flex;
    flex-direction: row-reverse;
    justify-content: flex-start;
    align-items: center;
    padding: 24px 36px;
  }
}
@media only screen and (max-width: 480px) {
  .app-dialog-box .content-wrapper .art-container {
    display: none;
  }
}
.app-dialog-box .content-wrapper .art-container .illustration__non-mobile {
  display: block;
  width: 100%;
  border-radius: 10px;
}
@media only screen and (max-width: 480px) {
  .app-dialog-box .content-wrapper .art-container .illustration__non-mobile {
    display: none;
  }
}
.app-dialog-box .content-wrapper .art-container .illustration__mobile {
  display: none;
  border-radius: 10px;
}
@media only screen and (max-width: 480px) {
  .app-dialog-box .content-wrapper .art-container .illustration__mobile {
    display: block;
  }
}
.app-dialog-box .content-wrapper .art-container h3 {
  margin: 16px 0;
  font-size: 40px;
  color: #021431;
}
@media (max-width: 610px) {
  .app-dialog-box .content-wrapper .art-container h3 {
    font-size: 22px;
    margin-bottom: 8px;
  }
}
.app-dialog-box .content-wrapper .text-container {
  width: 55%;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: left;
}
@media (max-width: 610px) {
  .app-dialog-box .content-wrapper .text-container {
    width: unset;
  }
}
.app-dialog-box .content-wrapper .text-container .text {
  padding: 36px;
}
@media (max-width: 610px) {
  .app-dialog-box .content-wrapper .text-container .text {
    padding: 18px 36px;
  }
}
@media only screen and (max-width: 480px) {
  .app-dialog-box .content-wrapper .text-container .text .instructions-wrapper {
    margin-bottom: 0;
  }
}
.app-dialog-box .content-wrapper .text-container h3 {
  margin: 16px 0 24px 0;
  font-size: 22px;
  color: #021431;
}
@media only screen and (max-width: 480px) {
  .app-dialog-box .content-wrapper .text-container h3 {
    font-size: 18px;
  }
}
.app-dialog-box .content-wrapper .text-container p {
  font-weight: 400;
}
.app-dialog-box .content-wrapper .text-container .button-container {
  width: 100%;
}
@media only screen and (max-width: 480px) {
  .app-dialog-box .content-wrapper .text-container .button-container {
    position: relative;
  }
}
.app-dialog-box .content-wrapper .text-container .button-container .ui-nav.ctas {
  padding: 20px 32px;
  border-top: 1px solid #a2a3a7;
}
.app-dialog-box .content-wrapper .text-container .button-container .ui-nav.ctas div,
.app-dialog-box .content-wrapper .text-container .button-container .ui-nav.ctas a {
  margin: 2px 0;
  position: relative;
  display: inline-block;
  padding: 0 25px;
  background-color: #1fb0fa;
  color: #ffffff;
  font-size: 14px;
  line-height: 36px;
  font-weight: 600;
  vertical-align: middle;
  text-decoration: none;
  text-align: center;
  border-radius: 5px;
  transition: transform 0.2s ease-out, background-color 0.2s ease-out, color 0.2s ease-out;
  user-select: none;
}
@media only screen and (max-width: 611px) {
  .app-dialog-box .content-wrapper .text-container .button-container .ui-nav.ctas {
    display: flex;
    flex-direction: column-reverse;
    border-top: none;
  }
}
/* Best not to edit this */
.smart-tooltip {
  position: absolute;
  display: none;
  transition: top ease-out 0.3s, left ease-out 0.3s;
  user-select: none;
}
/* Edit this */
.tooltip-container {
  position: relative;
}
.tooltip-container:before {
  content: "";
  position: absolute;
  left: -12px;
  top: -10px;
  border: solid;
  border-color: #e5e7ea transparent;
  border-width: 16px 8px 0 8px;
  transform: rotate(-270deg);
}
.tooltip-container .tooltip__close {
  position: absolute;
  top: 8px;
  right: 8px;
  font-size: 18px;
  cursor: pointer;
}
.tooltip-container .tooltip__body {
  width: 150px;
  position: absolute;
  left: 0;
  top: -20px;
  padding: 10px 12px;
  background: #e5e7ea;
  font-size: 12px;
  border-radius: 3px;
}
