.gift-card-content {
	background:		#ffffff;
	border-radius:	10px;

	@media @split-screen-down {
		margin-left:	1.5rem;
		margin-right:	1.5rem;
	}

	@media @mobile-portrait-down {
		margin-top:		1rem;
		margin-left:	1rem;
		margin-right:	1rem;
	}

	.image-wrapper {
		max-width:		330px;
		position:		relative;
		margin:			0 auto;
		margin-bottom:	2rem;
		border:			.625rem solid #ffffff;
		border-bottom:	none;
		border-radius:	10px;

		@media @mobile-portrait-down {
			max-width:	200px;
		}

		& > img {
			width:	100%;
		}

		& > div {
			position:				absolute;
			width:					100%;
			height:					100%;
			top:					0;
			left:					0;
			background-size:		cover;
			background-repeat:		no-repeat;
			background-position:	center center;
			border-radius:			10px;
		}
	}

	.input-wrapper {
		&:first-child {
			margin-top:		2rem;
			margin-bottom:	8px;
		}
	}

	.copied-text {
		display:		none;
		max-width:		400px;
		margin:			12px auto;
		padding:		8px 0;
		color:			#ffffff;
		background: 	lighten(@prizeout-green, 10%);
		border-radius:	0;
		border-color:	@prizeout-green;

		@media @mobile-portrait-down {
			max-width:		100%;
			margin-left:	1rem;
			margin-right:	1rem;
		}
	}

	.content-break {
		position:		relative;

		.break {
			width:			100px;
			height:			1px;
			background:		lighten(@light-grey-text-color, 10%);

			@media @tablet-landscape-down {
				width:		175px;
			}

			@media @mobile-portrait-down {
				width:		81px;
			}

			&__left {
				margin-left:	24px;

				@media @mobile-portrait-down {
					margin-left:	1rem;
				}
			}
			
			&__right {
				margin-right:	24px;

				@media @mobile-portrait-down {
					margin-right:	1rem;
				}
			}
		}

		&:before {
			left:	-12px;
		}

		&:after {
			right:	-12px;
		}

		&:before,
		&:after {
			content: 		" ";
			position: 		absolute;
			width: 			24px;
			height: 		24px;
			background: 	#f7f7fa;
			border-radius: 	50%;
		}
	}

	table {
		margin:	1rem auto;
	}
}