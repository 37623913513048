/* Best not to edit this */
.smart-tooltip {
	position: 			absolute;
	display:			none;
	transition:			top ease-out 0.3s, left ease-out 0.3s;
	user-select:		none;
}

/* Edit this */
.tooltip-container {
	position:	relative;

	&:before {
		content: 		"";
		position: 		absolute;
		left: 			-12px;
		top:			-10px;
		border: 		solid;
		border-color: 	#e5e7ea transparent;
		border-width: 	16px 8px 0 8px;
		transform:		rotate(-270deg)
	}

	.tooltip__close {
		position:			absolute;
		top:				8px;
		right:				8px;
		font-size:			18px;
		cursor:				pointer;
	}

	.tooltip__body {
		width:				150px;
		position:			absolute;
		left:				0;
		top:				-20px;
		padding:			10px 12px;
		background:			#e5e7ea;
		font-size:			12px;
		border-radius:		3px;
	}
}