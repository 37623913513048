.claim-content {
	background:		#ffffff;
	border-radius:	10px;

	@media @split-screen-down {
		margin-left:	1.5rem;
		margin-right:	1.5rem;
	}

	@media @mobile-portrait-down {
		margin-top:		1rem;
		margin-left:	1rem;
		margin-right:	1rem;
	}
}